import request from '@/utils/request'
import qs from 'qs'
// 添加服务评价
export function addEvaluation (worksid,ptevaluation_x,fwsevaluation_x,ptevaluation,fwsevaluation) {
  let data = {
    worksid,ptevaluation_x,fwsevaluation_x,ptevaluation,fwsevaluation
  }
  data = qs.stringify(data)
  return request({
    url: '/author/addEvaluation',
    method: 'post',
    data
  })
}
// 查看服务评价列表
export function selectevaluation (id,page,limit) {
  let data = {
    id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/author/selectevaluation',
    method: 'post',
    data
  })
}
