<template>
  <div>
    <div class="authortitle">服务评价</div>
    <div>
      <div class="container_table">
        <el-table
          :header-cell-style="headeRowClass"
          :data="tableData"
          stripe
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column
            prop="title"
            label="文章标题"
            width="300"
            align="center"
            sortable
          >
          </el-table-column>
          <el-table-column label="服务商" width="180" align="center" sortable>
            <template slot-scope="scope">
              <div>
                <span>{{
                  scope.row.fwsevaluation ? scope.row.fwsevaluation : "无"
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="平台" width="180" align="center" sortable>
            <template slot-scope="scope">
              <div>
                <span>{{
                  scope.row.ptevaluation ? scope.row.ptevaluation : "无"
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-show="scope.row.state == 0"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >评价</el-button
              >
              <el-button
                size="mini"
                v-show="scope.row.state == 1"
                type="danger"
                @click="pingjiawan"
                >已评价</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div
          class="ygdPage"
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          <el-pagination
            class="fy"
            small
            @size-change="handleSizeChange"
            :page-size="pagesize"
            :page-sizes="[10, 20, 30]"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="current_change"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectevaluation } from "../../../API/ZZD/service.js";
export default {
  data() {
    return {
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      tableData: [], // 提现记录列表
    };
  },
  mounted() {
    this.servicelist();
  },
  methods: {
    // 更改表头样式
    headeRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F2F2F2;color: #666666;font-weight: 700;";
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.servicelist();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.servicelist();
    },
    handleDelete(index, row) {
      //评价服务商
      console.log(row);
      if (row.name) {
        this.$router.push({
          path: "/Pending/servicepj/detail",
          query: {
            id: 8,
            wid: row.id,
            fwid: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/Pending/servicepj/detail",
          query: {
            id: 8,
            wid: row.id,
            fwid: 0,
          },
        });
      }
    },
    servicelist() {
      //服务商列表
      selectevaluation(
        localStorage.getItem("ZZId"),
        this.currentPage,
        this.pagesize
      ).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.data;
      });
    },
    pingjiawan() {
      this.$message.success("您已评价，请勿重新评价");
    },
  },
};
</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
</style>